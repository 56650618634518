//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      images: {
        logo: "./images/side/logo.svg",
        envelope: "./images/side/envelope.svg",
        column: "./images/side/column.svg",
        tottori: "./images/side/tel-tottori.svg",
        tokyo: "./images/side/tel-tokyo.svg",
        time: "./images/side/tel-time.svg"
      },
      isActive: false,
      position: 0,
      mainarea: "",
      sidearea: "",
      mobile: ""
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.mainarea = document.querySelector(".main");
    this.side = document.querySelector(".side");
    var device = window.navigator.userAgent;
    var mobile = device.indexOf("iPhone") > 0 || device.indexOf("Android") > 0 && device.indexOf("Mobile") > 0;

    if (mobile) {
      this.mobile = "mobile";
    }
  },
  methods: {
    toggleNav: function toggleNav() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.position = this.mainarea.getBoundingClientRect().top;
        this.mainarea.style.top = this.position + "px";
        this.mainarea.style.position = "fixed"; // this.sidearea.style.zIndex = 0
      } else {
        this.mainarea.style.position = "static"; // this.sidearea.style.zIndex = 1000

        window.scrollTo(0, -this.position);
      }
    }
  }
};