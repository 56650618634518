var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "content company_overview", attrs: { id: "overview" } },
    [
      _c("h3", { staticClass: "content_heading" }, [
        _vm._v("\n    会社概要\n  "),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "company_table" }, [
        _c("tr", [
          _c("th", [_vm._v("商号")]),
          _vm._v(" "),
          _c("td", [
            _vm._v("\n        " + _vm._s(_vm.contents.name) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("本社・工場")]),
          _vm._v(" "),
          _c("td", [
            _c("div", {
              staticClass: "company_table_map",
              domProps: { innerHTML: _vm._s(_vm.contents.hq) },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("東京営業所")]),
          _vm._v(" "),
          _c("td", [
            _c("div", {
              staticClass: "company_table_map",
              domProps: { innerHTML: _vm._s(_vm.contents.tokyo) },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("資本金")]),
          _vm._v(" "),
          _c("td", [
            _vm._v("\n        " + _vm._s(_vm.contents.capital) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("設立")]),
          _vm._v(" "),
          _c("td", [
            _vm._v("\n        " + _vm._s(_vm.contents.date) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("創業")]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n        " + _vm._s(_vm.contents.establishment) + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("代表者")]),
          _vm._v(" "),
          _c("td", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.contents.ceo) } }),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("従業員数")]),
          _vm._v(" "),
          _c("td", [
            _vm._v("\n        " + _vm._s(_vm.contents.employee) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("取引銀行")]),
          _vm._v(" "),
          _c("td", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.contents.bank) } }),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("業務内容")]),
          _vm._v(" "),
          _c("td", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.contents.work) } }),
          ]),
        ]),
        _vm._v(" "),
        _c("tr", [
          _vm._m(0),
          _vm._v(" "),
          _c("td", [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.contents.invoice) },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _vm._v("適格請求書発行\n        "),
      _c("br"),
      _vm._v("事業者登録番号\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }