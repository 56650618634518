var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "column_side" }, [
    _c("aside", { staticClass: "column_side_content" }, [
      _c("h4", { staticClass: "column_side_heading" }, [
        _vm._v("\n      最新記事\n    "),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "column_side_new" },
        _vm._l(_vm.column.newItem, function (item) {
          return _c(
            "li",
            { key: item.id },
            [
              _c(
                "div",
                { staticClass: "column_side_new_inner" },
                [
                  _c("time", { staticClass: "column_side_new_date" }, [
                    _vm._v(
                      "\n            " + _vm._s(item.date) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "column_side_new_title",
                      attrs: {
                        to: { name: "cdetail", query: { id: item.id } },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getColumnArticle(item.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.title) + "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "column_side_new_image",
                  attrs: { to: { name: "cdetail", query: { id: item.id } } },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getColumnArticle(item.id)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: item.image
                        ? item.image.url +
                          "?fit=fill&fill-color=ffffff&w=80&h=56"
                        : "./images/column/noimage.svg",
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "column_side_content list" }, [
      _c("h4", { staticClass: "column_side_heading" }, [
        _vm._v("\n      カテゴリー\n    "),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "column_side_list" },
        _vm._l(_vm.column.categoryList, function (item) {
          return _c(
            "li",
            { key: item.name.id },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "clist", query: { category: item.name.id } },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changeCategory(item.name.id)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(item.name.name) +
                      "\n          (" +
                      _vm._s(item.c) +
                      ")\n        "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "column_side_content list" }, [
      _c("h4", { staticClass: "column_side_heading" }, [
        _vm._v("\n      月別\n    "),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "column_side_list" },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "column_side_new_image",
                  attrs: { to: { name: "clist" } },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.changeMonth(false)
                    },
                  },
                },
                [_vm._v("\n          全ての期間\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.column.yearList, function (item) {
            return _c(
              "li",
              { key: item.y },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: { name: "clist", query: { date: item.y } } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.changeMonth(item.y)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.y) +
                        "\n          (" +
                        _vm._s(item.c) +
                        ")\n        "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }