var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("section", { staticClass: "content footer_contact" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "content_text _tac" }, [
        _vm._v(
          "\n      お客様のご予算・ご要望をお気軽にご相談ください。\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer_contact_inner" }, [
        _c("section", { staticClass: "footer_contact_type" }, [
          _c("h3", { staticClass: "footer_contact_heading" }, [
            _vm._v("\n          お電話でのお問い合わせ\n        "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "footer_contact_text-tel" }, [
            _c("img", {
              attrs: {
                src: _vm.images.contact,
                alt: "鳥取本社営業所：0857-51-8686　東京営業所：03-3980-3344　受付時間：9時〜17時（土・日・祝を除く）",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(1),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "footer_main" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "scroll-to",
              rawName: "v-scroll-to",
              value: "#app",
              expression: "'#app'",
            },
          ],
          staticClass: "pagetop _spHide",
          attrs: { href: "#app" },
        },
        [_c("img", { attrs: { src: _vm.images.pagetop, alt: "ページトップ" } })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer_main_inner" }, [
        _c("h2", { staticClass: "footer_main_heading" }, [
          _c("img", {
            attrs: { src: _vm.images.logo, alt: "株式会社ファイナール" },
          }),
        ]),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._m(3),
    ]),
    _vm._v(" "),
    _c("aside", { staticClass: "footer_banner" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "scroll-to",
              rawName: "v-scroll-to",
              value: "#app",
              expression: "'#app'",
            },
          ],
          staticClass: "pagetop _pcHide",
          attrs: { href: "#app" },
        },
        [_c("img", { attrs: { src: _vm.images.pagetop, alt: "ページトップ" } })]
      ),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: _vm.images.banner[0], alt: "FSSC 22000" },
          }),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("img", {
            attrs: { src: _vm.images.banner[1], alt: "ISO 14001:2015" },
          }),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("img", { attrs: { src: _vm.images.banner[2], alt: "JIHFS GMP" } }),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("img", { attrs: { src: _vm.images.banner[3], alt: "JAS" } }),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("img", {
            attrs: { src: _vm.images.banner[4], alt: "ユースエール2016" },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "content_heading" }, [
      _vm._v("\n      健康茶・健康食品の"),
      _c("br", { staticClass: "_pcHide" }),
      _vm._v("製造委託をお考えの方\n    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "footer_contact_type" }, [
      _c("h3", { staticClass: "footer_contact_heading" }, [
        _vm._v("\n          メールでのお問い合わせ"),
        _c("span", { staticClass: "_spHide" }, [_vm._v("・")]),
        _c("br", { staticClass: "_pcHide" }),
        _vm._v("サンプル請求\n        "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "footer_contact_text-mail" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-attention btn-medium",
            attrs: { href: "contact.php#mail" },
          },
          [
            _c("i", { staticClass: "fa fa-envelope-o" }),
            _vm._v("お問い合わせ"),
            _c("i", { staticClass: "fa fa-angle-right" }),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v("24時間受付\n          "),
        _c("br"),
        _vm._v("※3営業日以内に担当者よりご返答いたします。\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "footer_nav" }, [
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "tea.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("健康茶製造"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "food.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("健康食品製造"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "qc.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("品質管理"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "technology.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("ファイナールの経験・技術"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "result.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("企画実績"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "oem.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("お取引について"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "company.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("企業情報"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "faq.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("よくあるご質問"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "column.html" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("コラム"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "contact.php" } }, [
            _c("i", { staticClass: "fa fa-angle-right" }),
            _vm._v("お問い合わせ"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_main_inner" }, [
      _c("section", { staticClass: "footer_address" }, [
        _c("h3", { staticClass: "footer_address_heading" }, [
          _vm._v("\n          鳥取本社営業所：\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "footer_address_text" }, [
          _vm._v(
            "\n          〒680-1167　鳥取県鳥取市上味野15番地\n          "
          ),
          _c("br"),
          _vm._v("TEL 0857-51-8686 / FAX 0857-51-8881\n          "),
          _c("br"),
          _c("a", { attrs: { href: "company.html" } }, [
            _c("i", { staticClass: "fa fa-angle-double-right" }),
            _vm._v("企業情報へ"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "footer_address" }, [
        _c("h3", { staticClass: "footer_address_heading" }, [
          _vm._v("\n          東京営業所：\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "footer_address_text" }, [
          _vm._v("\n          〒171-0021東京都豊島区西池袋1-7-10\n          "),
          _c("br"),
          _vm._v("ビルドT9ビル7F\n          "),
          _c("br"),
          _vm._v("TEL 03-3980-3344 / FAX 03-3980-3378\n        "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_copyright" }, [
      _c("small", [_vm._v("© 2019 finarl. Co., Ltd.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }