var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content tour", attrs: { id: "tour" } },
    [
      _c("h2", { staticClass: "content_heading" }, [
        _vm._v("\n    工場見学ツアー\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content_text _spHide" }, [
        _vm._v(
          "\n    マップ上の番号をクリックすると、ファイナールの工場内の様子を見学することができます。\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "ol",
        { staticClass: "tour_map" },
        _vm._l(_vm.tourMap.place, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              on: {
                click: function ($event) {
                  return _vm.openModal(item.id)
                },
              },
            },
            [_c("img", { attrs: { src: item.icon, alt: item.name } })]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("tour-slider"),
      _vm._v(" "),
      _c("div", { staticClass: "tour_360" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-active btn-long",
            attrs: {
              href: "https://finarl.co.jp/finarl_360/360/",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticClass: "btn_icon",
              attrs: { src: _vm.images.icon, alt: "" },
            }),
            _vm._v("\n      360°Viewを見る\n      "),
            _c("i", { staticClass: "fa fa-angle-right" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("modal-window"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }