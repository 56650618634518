//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
var KEY = '60a4cd21-6d4c-4ec5-a44c-706040a55e11';
export default {
  data: function data() {
    return {
      contents: [{}]
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.getContents();
  },
  methods: {
    getContents: function getContents(year) {
      var _this = this;

      axios.get('https://finarlkanri.microcms.io/api/v1/history?limit=50', {
        headers: {
          'X-API-KEY': KEY
        }
      }).then(function (response) {
        var content = response.data.contents;
        _this.contents = content;
      });
    }
  }
};