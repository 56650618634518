var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "image" }, [
      _c(
        "li",
        { class: { isActive: _vm.isActive === 0 } },
        [_vm._t("image1")],
        2
      ),
      _vm._v(" "),
      _c(
        "li",
        { class: { isActive: _vm.isActive === 1 } },
        [_vm._t("image2")],
        2
      ),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "thumb" }, [
      _c(
        "li",
        {
          class: { isActive: _vm.isActive === 0 },
          on: {
            click: function ($event) {
              return _vm.changeImage(0)
            },
          },
        },
        [_vm._t("image1")],
        2
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { isActive: _vm.isActive === 1 },
          on: {
            click: function ($event) {
              return _vm.changeImage(1)
            },
          },
        },
        [_vm._t("image2")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }