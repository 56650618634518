var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "content" }, [
    _c(
      "section",
      { staticClass: "content contact_mail-check _mb0", attrs: { id: "mail" } },
      [
        _c("h3", { staticClass: "content_heading" }, [
          _vm._v("\n      送信内容のご確認\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content_text" }, [
          _vm._v(
            "\n      以下の内容でお間違えがないかご確認の上、送信を行ってください。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact_table_wrapper" }, [
          _c("form", [
            _c("table", { staticClass: "contact_table" }, [
              _c("tr", [
                _c("th", [_vm._v("\n              会社名\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.company) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("\n              部署名\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.division) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("\n              お名前\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.name) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("\n              フリガナ\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.kana) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", { staticClass: "_vat" }, [
                  _vm._v("\n              ご住所\n            "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "form_zip" }, [
                    _vm._v(
                      "\n                郵便番号　〒\n                " +
                        _vm._s(_vm.contact.input.zip) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.address) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("\n              電話番号\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.tel) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v("\n              メールアドレス\n            "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.email) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(
                    "\n              メールアドレス（確認用）\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.remail) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "contact_table_bg" }, [
                _c("th", [_vm._v("\n              味サンプル\n            ")]),
                _vm._v(" "),
                _c("td", [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.contact.input.taste_sample) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.contact.input.taste_sample === "要"
                    ? _c(
                        "ul",
                        _vm._l(
                          _vm.contact.input.taste_sample_select,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item) +
                                  "\n                "
                              ),
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", { staticClass: "contact_table_bg" }, [
                _c("th", [
                  _vm._v("\n              梱包サンプル\n            "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.contact.input.box_sample) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.contact.input.taste_sample === "要"
                    ? _c(
                        "ul",
                        _vm._l(
                          _vm.contact.input.box_sample_select,
                          function (item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item) +
                                  "\n                "
                              ),
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.contact.input.address_2
                ? _c("tr", [
                    _c("th", { staticClass: "_vat" }, [
                      _vm._v("\n              送付先\n            "),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("p", { staticClass: "form_zip" }, [
                        _vm._v(
                          "\n                郵便番号　〒\n                " +
                            _vm._s(_vm.contact.input.zip_2) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.contact.input.address_2) +
                          "\n            "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v("\n              ご希望の連絡方法\n            "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.contact.input.contact === "1" ? "電話" : "メール"
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", { staticClass: "_vat" }, [
                  _vm._v("\n              お問い合わせ内容\n            "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.contact.input.body) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "contact_table_footer",
                    attrs: { colspan: "2" },
                  },
                  [
                    _c("p", { staticClass: "contact_table_footer_btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-attention-border btn-long",
                          attrs: { type: "button" },
                          on: { click: _vm.goBack },
                        },
                        [
                          _c("i", { staticClass: "fa fa-angle-left" }),
                          _vm._v(" 戻る\n                "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-attention btn-long",
                          attrs: { type: "button" },
                          on: { click: _vm.sendMail },
                        },
                        [
                          _c("i", { staticClass: "fa fa-angle-right" }),
                          _vm._v(" 送信する\n                "),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_text contact_box _mt0" }, [
      _vm._v(
        "\n        送信できない場合は、以下のメールアドレスからご連絡ください。\n        "
      ),
      _c("br"),
      _c("a", { attrs: { href: "mailto:contact@finarl.co.jp" } }, [
        _vm._v("contact@finarl.co.jp"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }