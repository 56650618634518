import { render, staticRenderFns } from "./techAccordion.vue?vue&type=template&id=126bac6a&scoped=true&"
import script from "./techAccordion.vue?vue&type=script&lang=js&"
export * from "./techAccordion.vue?vue&type=script&lang=js&"
import style0 from "./techAccordion.vue?vue&type=style&index=0&id=126bac6a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126bac6a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('126bac6a')) {
      api.createRecord('126bac6a', component.options)
    } else {
      api.reload('126bac6a', component.options)
    }
    module.hot.accept("./techAccordion.vue?vue&type=template&id=126bac6a&scoped=true&", function () {
      api.rerender('126bac6a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/techAccordion.vue"
export default component.exports