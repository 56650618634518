var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content homeColumn" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "homeColumn_list" },
      _vm._l(_vm.column.homeItem, function (item) {
        return _c("li", { key: item.id }, [
          _c(
            "a",
            {
              attrs: { href: "cdetail.html?id=" + item.id },
              on: {
                click: function ($event) {
                  return _vm.getColumnArticle(item.id)
                },
              },
            },
            [
              _c("h3", { staticClass: "homeColumn_heading" }, [
                _vm._v("\n          " + _vm._s(item.title) + "\n        "),
              ]),
              _vm._v(" "),
              _c("time", { staticClass: "homeColumn_date" }, [
                _vm._v("\n          " + _vm._s(item.date) + "\n        "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "homeColumn_image" }, [
                _c("img", {
                  attrs: {
                    src: item.image
                      ? item.image.url +
                        "?fit=fill&fill-color=ffffff&w=440&h=310"
                      : "./images/column/noimage.svg",
                    alt: "",
                  },
                }),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "content_heading-btn" }, [
      _vm._v("\n    コラム\n    "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary-border btn-medium _spHide",
          attrs: { href: "column.html" },
        },
        [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("一覧を見る")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "homeColumn_more" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary-border btn-small _pcHide",
          attrs: { href: "column.html" },
        },
        [_c("i", { staticClass: "fa fa-angle-right" }), _vm._v("一覧を見る")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }