import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import columnSide from '../../components/columnSide';
import { mapState } from 'vuex';
export default {
  name: 'ColumnList',
  components: {
    columnSide: columnSide
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState(['column'])), {}, {
    calcPaginate: function calcPaginate() {
      return ~~(this.column.totalCount / 10 + 1);
    }
  }),
  created: function created() {},
  mounted: function mounted() {
    this.createCanonical();
    document.title = 'コラム | 株式会社ファイナール';
    var query = this.$route.query;

    if (Object.keys(query).length) {
      // クエリがあれば対応リスト取得
      this.getColumnList(query);
    } else if (this.column.columnList[0].id === '') {
      // 一度もリストを取得していなければ全取得
      this.getColumnList({
        date: false
      });
    } else {
      // それ以外はリストへリダイレクト
      this.$router.push({
        name: 'clist'
      }).catch(function () {});
    }
  },
  methods: {
    getColumnList: function getColumnList(obj) {
      // 一覧取得
      this.$store.dispatch('column/getColumnList', obj);
    },
    getColumnArticle: function getColumnArticle(id) {
      // 記事取得
      this.$store.dispatch('column/getColumnArticle', id);
    },
    changePage: function changePage(n) {
      // ページネート
      if (n <= 0 || n - 1 > ~~(this.column.totalCount / 10)) {
        return;
      }

      if (this.column.type === 'all') {
        this.$store.dispatch('column/getColumnList', {
          page: n - 1
        });
      }

      if (this.column.type === 'date') {
        this.$store.dispatch('column/getColumnList', {
          page: n - 1,
          date: this.column.year
        });
      }

      if (this.column.type === 'category') {
        this.$store.dispatch('column/getColumnList', {
          page: n - 1,
          category: this.column.categoryID
        });
      }
    },
    createCanonical: function createCanonical() {
      var canonicalUrl = location.href.split('?')[0];
      var isCanonical = !!document.querySelector('link[rel="canonical"]');
      var link = isCanonical ? document.querySelector('link[rel="canonical"]') : document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);

      if (!isCanonical) {
        document.head.appendChild(link);
      }
    }
  }
};