var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column_wrapper" },
    [
      _c("article", { staticClass: "column_list" }, [
        _c("div", [
          _c(
            "ul",
            { staticClass: "column_list_body" },
            _vm._l(_vm.column.showList, function (item) {
              return _c(
                "li",
                { key: item.id },
                [
                  _c(
                    "div",
                    { staticClass: "column_list_inner" },
                    [
                      _c("time", { staticClass: "column_list_date" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.date) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "column_list_category" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.category ? item.category.name : "") +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "column_list_title",
                          attrs: {
                            to: { name: "cdetail", query: { id: item.id } },
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.getColumnArticle(item.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.title) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "column_list_image",
                      attrs: {
                        to: { name: "cdetail", query: { id: item.id } },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getColumnArticle(item.id)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.image
                            ? item.image.url +
                              "?fit=fill&fill-color=ffffff&w=220&h=150"
                            : "./images/column/noimage.svg",
                          alt: "noimage",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "paginate" },
          [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.changePage(_vm.column.page)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-angle-double-left" })]
            ),
            _vm._v(" "),
            _vm._l(_vm.column.paginate, function (item) {
              return _c(
                "li",
                {
                  key: item,
                  class: { isActive: item === _vm.column.page + 1 },
                  on: {
                    click: function ($event) {
                      return _vm.changePage(item)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(item) + "\n      ")]
              )
            }),
            _vm._v(" "),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.changePage(_vm.column.page + 2)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-angle-double-right" })]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("column-side"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }