var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "faq_item", on: { click: _vm.toggleAccordion } },
    [
      _c(
        "h3",
        { staticClass: "faq_question", class: { isOpen: _vm.isActive } },
        [_vm._t("question")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive",
            },
          ],
          staticClass: "faq_answer",
        },
        [_c("p", [_vm._t("answer")], 2)]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }