var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "content news_detail" }, [
    _c("h3", { staticClass: "news_detail_heading" }, [
      _vm._v("\n    " + _vm._s(_vm.news.newsDetail.title) + "\n  "),
    ]),
    _vm._v(" "),
    _c("time", { staticClass: "news_list_date" }, [
      _vm._v("\n    " + _vm._s(_vm.news.newsDetail.date) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "news_detail_body",
      domProps: { innerHTML: _vm._s(_vm.news.newsDetail.body) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "news_detail_btn" },
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-primary-border btn-long",
            attrs: { to: { name: "nlist" } },
          },
          [
            _c("i", { staticClass: "fa fa-angle-left" }),
            _vm._v(" 一覧へ戻る\n    "),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }