import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.regexp.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
var KEY = '60a4cd21-6d4c-4ec5-a44c-706040a55e11';
export default {
  data: function data() {
    return {
      contents: [{
        hq: '',
        tokyo: ''
      }]
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.getContents();
  },
  methods: {
    getContents: function getContents(year) {
      var _this = this;

      axios.get('https://finarlkanri.microcms.io/api/v1/company?limit=1', {
        headers: {
          'X-API-KEY': KEY
        }
      }).then(function (response) {
        var content = response.data.contents[0];
        Object.keys(content).map(function (el) {
          return content[el] = content[el].replace(/\n/g, '<br>');
        });
        _this.contents = content;
      });
    }
  }
};