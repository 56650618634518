//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isActive: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    toggleAccordion: function toggleAccordion() {
      this.isActive = !this.isActive;
    }
  }
};