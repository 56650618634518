//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isActive: 0
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeImage: function changeImage(i) {
      this.isActive = i;
    }
  }
};