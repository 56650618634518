var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("a", { staticClass: "_pcHide", attrs: { href: "news.html" } }, [
      _c("section", { staticClass: "headline" }, [
        _c("h2", { staticClass: "headline_heading" }, [
          _vm._v("\n        おしらせ\n      "),
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "headline_heading-2" }, [
          _c("time", { attrs: { datetime: "2019-06-17" } }, [
            _vm._v(
              "\n          " + _vm._s(_vm.news.showList[0].date) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "headline_text" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.news.showList[0].title) + "\n      "
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "headline _spHide" }, [
      _c("h2", { staticClass: "headline_heading" }, [
        _vm._v("\n      おしらせ\n    "),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "headline_heading-2" }, [
        _c("time", { attrs: { datetime: "2019-06-17" } }, [
          _vm._v("\n        " + _vm._s(_vm.news.showList[0].date) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "headline_text" }, [
        _vm._v("\n      " + _vm._s(_vm.news.showList[0].title) + "\n    "),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "headline_more _spHide", attrs: { href: "news.html" } },
        [_vm._v("もっと見る")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "headline" }, [
      _c("h2", { staticClass: "headline_heading headline_heading-orange" }, [
        _vm._v("\n      サンプル送付対応をはじめました\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "headline_more" }, [
      _c("i", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "headline_more _pcHide", attrs: { href: "news.html" } },
      [_c("i", { staticClass: "fa fa-angle-right" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }