var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { isActive: _vm.tourMap.isActive },
      on: { click: _vm.closeModal },
    },
    [
      _c("div", { staticClass: "modal_inner" }, [
        _c("h3", { staticClass: "modal_heading" }, [
          _vm._v("\n      " + _vm._s(_vm.tourMap.content.name) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal_image" },
          _vm._l(_vm.tourMap.content.images, function (item, index) {
            return _c("img", { key: index, attrs: { src: item, alt: "" } })
          }),
          0
        ),
        _vm._v(" "),
        _vm.tourMap.content.video
          ? _c("div", { staticClass: "modal_video" }, [
              _c("video", {
                attrs: { src: _vm.tourMap.content.video, controls: "" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "modal_close",
          on: { click: _vm.closeModal },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }