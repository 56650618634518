var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tour_slider" },
    [
      _c(
        "hooper",
        { ref: "hooper", attrs: { settings: _vm.hooperSettings } },
        _vm._l(_vm.tourMap.place, function (item) {
          return _c(
            "slide",
            {
              key: item.id,
              class: { v360: item.name === "360° View" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openModal(item.id)
                },
              },
            },
            [
              _c("img", {
                staticClass: "thumb",
                attrs: { src: item.thumb, alt: "" },
              }),
              _vm._v(" "),
              _c("p", [
                _c("img", { attrs: { src: item.icon } }),
                _vm._v("\n        " + _vm._s(item.name) + "\n      "),
              ]),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          {
            staticClass: "hooper-button-prev",
            on: {
              click: function ($event) {
                return _vm.moveSlide(0)
              },
            },
          },
          [_c("img", { attrs: { src: _vm.navigation, alt: "前へ" } })]
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "hooper-button-next",
            on: {
              click: function ($event) {
                return _vm.moveSlide(1)
              },
            },
          },
          [_c("img", { attrs: { src: _vm.navigation, alt: "次へ" } })]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }