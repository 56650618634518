//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  components: {
    Hooper: Hooper,
    Slide: Slide,
    HooperPagination: HooperPagination
  },
  data: function data() {
    return {
      slide: [{
        id: 1,
        images: ['./images/slider/slider01_sp.jpg', './images/slider/slider01.jpg'],
        alt: '健康茶・健康食品の企画製造・OEM　健康食品も手がける健康茶製造メーカーだからできる強み。 テスト販売や試作品などの小ロットの場合でも、お気軽にご相談ください。'
      }, {
        id: 2,
        images: ['./images/slider/slider02_sp.jpg', './images/slider/slider02.jpg'],
        alt: '健康へのあくなき挑戦　ものづくりのプロとして誇りを持ち、本物の製品づくりを妥協することなく追求していきます。'
      }, {
        id: 3,
        images: ['./images/slider/slider03_sp.jpg', './images/slider/slider03.jpg'],
        alt: 'FSSC22000・健康食品GMP ダブル認証取得工場　信頼できる食品の安全・安心を提供いたします。'
      }],
      hooperSettings: {
        autoPlay: true,
        itemsToShow: 1,
        infiniteScroll: true,
        centerMode: true,
        mouseDrag: false,
        wheelControl: false,
        playSpeed: 5000,
        transition: 1000,
        hoverPause: false
      }
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};