//import indexView from './components/IndexView'
import newsList from './components/newsList';
import newsDetail from './components/newsDetail';
import columnList from './components/columnList';
import columnDetail from './components/columnDetail';
import contactHome from './components/contactHome';
import contactCheck from './components/contactCheck';
import contactSuccess from './components/contactSuccess';
export default [{
  path: '/news.html',
  component: newsList,
  meta: {
    title: ''
  },
  name: 'nlist'
}, {
  path: '/ndetail.html',
  component: newsDetail,
  meta: {
    title: ''
  },
  name: 'ndetail'
}, {
  path: '/column.html',
  component: columnList,
  meta: {
    title: ''
  },
  name: 'clist'
}, {
  path: '/cdetail.html',
  component: columnDetail,
  meta: {
    title: ''
  },
  name: 'cdetail'
}, {
  // path: '/finarl/contact.php', //テスト
  path: '/contact.php',
  component: contactHome,
  meta: {
    title: ''
  },
  name: 'contact'
}, {
  // path: '/finarl/contactcheck.php', //テスト
  path: '/contactcheck.php',
  component: contactCheck,
  meta: {
    title: ''
  },
  name: 'contactcheck'
}, {
  // path: '/finarl/contactsuccess.php', //テスト
  path: '/contactsuccess.php',
  component: contactSuccess,
  meta: {
    title: ''
  },
  name: 'contactsuccess'
}];