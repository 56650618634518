var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "hooper",
    { ref: "hooper", attrs: { settings: _vm.hooperSettings } },
    [
      _vm._l(_vm.slide, function (item) {
        return _c("slide", { key: item.id }, [
          _c("picture", [
            _c("source", {
              attrs: { media: "(max-width: 767px)", srcset: item.images[0] },
            }),
            _vm._v(" "),
            _c("img", { attrs: { src: item.images[1], alt: item.alt } }),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("hooper-pagination", {
        attrs: { slot: "hooper-addons" },
        slot: "hooper-addons",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }