//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      images: {
        contact: './images/footer/contact.svg',
        logo: './images/footer/logo.svg',
        pagetop: './images/pagetop.svg',
        banner: ['./images/footer/banner01.png', './images/footer/banner02.png', './images/footer/banner03.png', './images/footer/banner04.png', './images/footer/banner05.png']
      },
      isActive: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};