import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapState } from 'vuex';
export default {
  components: {
    Hooper: Hooper,
    Slide: Slide
  },
  data: function data() {
    return {
      navigation: './images/tour/navigation.svg',
      hooperSettings: {
        itemsToShow: 1.5,
        infiniteScroll: true,
        centerMode: true,
        mouseDrag: false,
        wheelControl: false,
        breakpoints: {
          768: {
            centerMode: true,
            itemsToShow: 4
          }
        }
      }
    };
  },
  computed: _objectSpread({}, mapState(['tourMap'])),
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    moveSlide: function moveSlide(n) {
      if (n) {
        this.$refs.hooper.slideNext();
        return;
      }

      this.$refs.hooper.slidePrev();
    },
    openModal: function openModal(id) {
      this.$store.commit('tourMap/toggleModal', id);
    }
  }
};