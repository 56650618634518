var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "content news_list _pt30" }, [
    _c("div", { staticClass: "news_list_archive" }, [
      _c(
        "select",
        {
          on: {
            change: function ($event) {
              return _vm.changeYear($event)
            },
          },
        },
        [
          _c("option", [_vm._v("過去のアーカイブ")]),
          _vm._v(" "),
          _vm._l(_vm.news.yearList, function (year) {
            return _c(
              "option",
              { key: year, attrs: { id: year }, domProps: { value: year } },
              [_vm._v("\n        " + _vm._s(year) + "年\n      ")]
            )
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("h3", { staticClass: "news_list_heading" }, [
      _vm._v("\n    お知らせ一覧\n  "),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "ul",
        { staticClass: "news_list_body" },
        _vm._l(_vm.news.showList, function (item) {
          return _c(
            "li",
            { key: item.id },
            [
              _c("time", { staticClass: "news_list_date" }, [
                _vm._v("\n          " + _vm._s(item.date) + "\n        "),
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "news_list_title",
                  attrs: { to: { name: "ndetail", query: { id: item.id } } },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getNewsArticle(item.id)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }