//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isActive: false,
      mainarea: '',
      sidearea: '',
      position: 0
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.mainarea = document.querySelector('.main');
    this.sidearea = document.querySelector('.side');
  },
  methods: {
    toggleAccordion: function toggleAccordion() {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.position = this.mainarea.getBoundingClientRect().top;
        this.mainarea.style.top = this.position + 'px';
        this.mainarea.style.position = 'fixed';
        this.sidearea.style.zIndex = 0;
      } else {
        this.mainarea.style.position = 'static';
        this.sidearea.style.zIndex = 1000;
        window.scrollTo(0, -this.position);
      }
    },
    closeModal: function closeModal(e) {
      if (e.target !== e.currentTarget) {
        return;
      }

      this.isActive = false;
      this.mainarea.style.position = 'static';
      this.sidearea.style.zIndex = 1000;
      window.scrollTo(0, -this.position);
    }
  }
};