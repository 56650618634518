import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: 'NewsList',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState(['news'])),
  created: function created() {},
  mounted: function mounted() {
    this.createCanonical();
    this.getNewsList();
  },
  methods: {
    getNewsList: function getNewsList(year) {
      this.$store.dispatch('news/getNewsList', year);
      document.title = 'お知らせ｜ 株式会社ファイナール';
    },
    getNewsArticle: function getNewsArticle(id) {
      this.$store.dispatch('news/getNewsArticle', id);
    },
    changeYear: function changeYear(e) {
      if (!/[0-9]./.test(e.target.value)) {
        this.$store.dispatch('news/changeYear');
        return;
      }

      console.log(e);
      this.$store.dispatch('news/changeYear', e.target.value);
    },
    createCanonical: function createCanonical() {
      var canonicalUrl = location.href;
      var isCanonical = !!document.querySelector('link[rel="canonical"]');
      var link = isCanonical ? document.querySelector('link[rel="canonical"]') : document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);

      if (!isCanonical) {
        document.head.appendChild(link);
      }
    }
  }
};