var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "itemWrap" }, [
    _c("section", { staticClass: "itemList" }, [
      _c("div", { staticClass: "itemList_item" }, [
        _c(
          "h4",
          { staticClass: "itemList_heading tech_list_heading" },
          [_vm._t("heading01")],
          2
        ),
        _vm._v(" "),
        _c("p", { staticClass: "itemList_text" }, [_vm._t("text01")], 2),
        _vm._v(" "),
        _c("p", { staticClass: "itemList_btn" }, [
          _c(
            "span",
            {
              staticClass: "btn btn-medium",
              class: {
                "btn-primary-border": !_vm.isActive,
                "btn-primary": _vm.isActive,
              },
              on: { click: _vm.toggleAccordion },
            },
            [
              _c("i", {
                staticClass: "fa fa-plus-circle",
                class: {
                  "fa-plus-circle": !_vm.isActive,
                  "fa-minus-circle": _vm.isActive,
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive,
                      expression: "!isActive",
                    },
                  ],
                },
                [_vm._v("詳細資料を見る")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive,
                      expression: "isActive",
                    },
                  ],
                },
                [_vm._v("詳細資料を閉じる")]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "itemList_image" }, [_vm._t("image01")], 2),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        class: { isActive: _vm.isActive },
        on: { click: _vm.closeModal },
      },
      [
        _c("div", { staticClass: "modal_inner" }, [
          _c("div", { staticClass: "modal_scroll" }, [
            _c(
              "h5",
              { staticClass: "content_heading-sub" },
              [_vm._t("heading02")],
              2
            ),
            _vm._v(" "),
            _c("p", { staticClass: "modal_text" }, [_vm._t("text02")], 2),
            _vm._v(" "),
            _c("p", { staticClass: "modal_image" }, [_vm._t("image02")], 2),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "modal_close",
            on: { click: _vm.closeModal },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }