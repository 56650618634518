import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: 'ContactHome',
  data: function data() {
    return {
      images: ['./images/contact/tel_sp.svg', './images/contact/tel.svg'],
      taste_sample: '',
      box_sample: '',
      isMobile: false,
      teas: [{
        id: 'taste_sample01',
        value: '1.便通',
        title: 'キャンドルブッシュ茶による驚きのスッキリ体感！',
        list: ['・キャンドルブッシュと難消化性デキストリンを配合しすっきり効果を期待できます。', '・紅茶をベースに、ラズベリーの甘い香りを加えることで、飲みやすく、無理なく続けられるブレンド茶に仕上げています。', '・サプリメント(顆粒タイプ)への商品展開も可能です。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample02',
        value: '2.抗糖化',
        title: 'サラシアとギムネマが血糖値や糖質に効果的です',
        list: ['・サラシアやギムネマを配合し、血糖値や糖質を気にされている方向けの配合です。', '・赤紫蘇の風味と植物由来の甘酸っぱい味がマッチングした飲みやすい味です。水色にもこだわっています。', '・今回はハーブ系の味付けですが、食事時にも飲める和風風味の配合も人気です。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample03',
        value: '3.美肌',
        title: '美容に嬉しいグリーンルイボス・ビタミン配合！',
        list: ['・美容にうれしい成分が赤いルイボス以上といわれている「グリーンルイボス」を使用しています。', '・そのほか、カテキン、クエン酸やビタミン成分など美容に有用な成分を含む茶葉を織り交ぜた美サポートティーです。', '・コラーゲンや乳酸菌、レスベラトロールなど、機能性粉末原料も配合可能です。', '・今回はグレープ香料を使用しておりますが、香り違いのサンプルも対応可能です。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample04',
        value: '4.あたため',
        title: 'ポカポカ原料「ヘスペリジン」配合のあったか紅茶',
        list: ['・ぽかぽかするお茶で巡りをよくすることに着目したお茶です。', '・古くからあたための漢方として利用されてきた「みかんの皮」とその成分である「ヘスペリジン」を配合しています。', '・紅茶とフルーティーなフレーバーで飲みやすく仕上げています。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample05',
        value: '5.免疫訴求',
        title: '甜茶と乳酸菌で花粉症対策にも！',
        list: ['・茶カテキンを豊富に含む「緑茶」と花粉症商品などで注目の「甜茶」をメインに配合したムズムズ対策のブレンド茶です。', '・発酵素材として、弊社独自原料の「発酵どくだみ」と人気の「乳酸菌」も配合しています。', '・香料や甘味料を使わずに、お茶原料だけで梅のような香りと甘酸っぱいやさしい味に仕上げています。', '・「緑茶」から「べにふうき緑茶」へのアレンジや、本品には「甜茶」の穏やかな甘みを使用していますが、甘さをより際立たせたい場合には「ステビア葉」の使用も可能です。　'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample06',
        value: '6.リラックス',
        title: 'GABA入りのジャスミン茶でリラックス',
        list: ['・仕事の合間やおうち時間に、ジャスミン茶とカモミールの甘い香りでホッとできるお茶です。', '・甘い香りの中にほんのりとレモングラスの風味を感じる、やさしい味に仕上げています。', '・リラックス系素材としてお馴染みのGABA（γ-アミノ酪酸）を100㎎配合しております。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample07',
        value: '7.野菜茶',
        title: 'ゴボウに生姜、漢方原料を配合した飲みやすいお茶です',
        list: ['・「ごぼう」と「生姜」、漢方や産前産後のお茶でお馴染みの「たんぽぽ根」、その他「ほうれん草」や「人参」など全17種類の野菜原料と麦茶のブレンドティーです。', '・本サンプルは野菜感を残したブレンドですが、もっと飲みやすさを重視したい、もっとクセを出したいなどの要望に合わせたブレンドが可能です。'],
        class: '',
        open: false,
        checked: false
      }, {
        id: 'taste_sample08',
        value: '8.妊活・妊婦・産後向けのお茶',
        title: 'ホット一息。女性に嬉しい原料配合のノンカフェインブレンドティー',
        list: ['・妊活中、妊娠中、授乳中にホッと一息できる、ノンカフェインブレンドティーです。', '・ノンカフェインティーでお馴染みのルイボス、タンポポ根コーヒーで有名なタンポポ根、あたため素材みかんの皮など、女性に優しいお茶原料を複数配合しています。', '・妊娠中、授乳中にサプリなどで摂取することの多い栄養成分＜ビタミンB１＞＜ビタミンB２＞＜ビタミンC＞＜葉酸＞をギュッと詰め込みました。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample09',
        value: '9.家族みんなで飲めるお茶',
        title: 'ルイボス・ほうじ茶ブレンドで毎日美味しく飲みやすい！',
        list: ['・代表的なハーブティーである「ルイボス」と日本茶としてなじみ深い「ほうじ茶」のコラボティーです。', '・ルイボスティーにほうじ茶の香ばしさが加わることで、ルイボスティー単体よりも飲みやすく感じる一杯です。', '・ノンカフェインのルイボスティーとローカフェインティーのほうじ茶のみ使用していますので、ご家族みんなで食事時にもお飲みいただけます。'],
        class: 'form_click_w100',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample10',
        value: '10.メグスリノキ紅茶',
        title: '目に良い原料を紅茶とブレンド、日常に取り入れやすいお茶です。',
        list: ['・「メグスリノキ」をはじめ、目に良いとされている原料を数種類配合しています。', '・ブルーベリー香料を配合しておりますのでフレーバーティー感覚でお飲みいただけます。', '・漬け出しのティーバッグなので長時間のパソコン作業などの合間にもお手軽に召し上がって頂けます。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample11',
        value: '11.ぽかぽか はちみつ紅茶',
        title: '今流行り「はちみつ紅茶」をファイナールが作ってみました！',
        list: ['・合成甘味料不使用。', '・自然な甘さが心と体をぽかぽかに。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }],
      foods: [{
        id: 'taste_sample12',
        value: '12.便通訴求顆粒',
        title: 'スッキリデトックスのキャンドルブッシュ粉末をフルーティーで美味しく仕上げました',
        list: ['・メイン配合している「キャンドルブッシュ末」は、キャンドルブッシュ中の有用な成分を保持したまま粉末化した当社オリジナルの加工原料です。', '・200億個以上の植物性乳酸菌と80種類以上の野菜、果物、穀物、野草の発酵熟成原料を配合し、すっきりデトックスをサポートします。', '・フルーティーな香りとすっきりした味わいで飲みやすく仕上げています。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample13',
        value: '13.ビタミンC顆粒',
        title: 'お手軽にレモン100個分のビタミンC！生活習慣を完全サポート',
        list: ['・美容・健康維持を目指して、お手軽にレモン100個分※　のビタミンCを摂取できるように考えられた処方です。', '・ビタミンＣに加え、ビタミンB12、それと相性の良い葉酸も配合しています。', '※一般社団法人全国清涼飲料連合会「レモン果実1個あたりのビタミンC量」の表示に関する件を参考。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample14',
        value: '14.青汁顆粒',
        title: 'フルーティーで飲みやすい青汁。フラクトオリゴ糖・発酵素材を配合',
        list: ['・メインの青汁素材は「国産大麦若葉末」と「北海道産クマザサ末」を使用しています。', '・さらに腸内環境に優しい原料「フラクトオリゴ糖」と、古くから体によいとされている「発酵素材」も多数配合しています。', '・フレーバー入りで青汁の青臭さをマスキングし、無理なく飲み続けれる味に仕上げています。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample15',
        value: '15.打錠品形状見本（丸型と三角型）',
        title: '',
        list: ['・製造用剤を使用して成形した、形状見本サンプルです。', '・構成原料は、マルチトール、粉末セルロース、グリセリン脂肪酸エステルです。'],
        class: 'form_click_w100',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample16',
        value: '16.青汁ＨＯＴラテ',
        title: '"新感覚な青汁"を試してみませんか',
        list: ['・青汁なのに、ラテ感覚でお飲みいただけます。', '・「栄養をもっとおいしく補いたい」をコンセプトに、青汁であることを忘れるほどの飲みやすさを追求しました。', '・フルーティーな青汁に飽きちゃったという方にもオススメです。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'taste_sample17',
        value: '17.キャンドルブッシュの粒',
        title: 'キャンドルブッシュをお手軽簡単な粒タイプにしました！',
        list: ['・キャンドルブッシュのスッキリ体感をお気軽に、サッと飲めます。', '・乳酸菌に加え、美容原料のメロンプラセンタも配合しています。'],
        class: '',
        open: false,
        checked: false,
        timer: false
      }],
      teaboxes: [{
        id: 'box_sample01',
        value: '平パック晒(白色)',
        size: ['70mm×80mm', '90mm×80mm'],
        text: '最もスタンダードな形パックで、様々な企画に対応可能',
        img: 'pack01.jpg',
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'box_sample02',
        value: '平パック未ざらし(茶色)',
        size: ['70mm×80mm', '90mm×80mm'],
        text: '未漂白のパルプを使用することで、自然派商品にピッタリ',
        img: 'pack02.jpg',
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'box_sample03',
        value: 'ミニパック(ティーパック糸有)',
        size: ['70mm×50mm'],
        text: '糸付きの平たいタイプのパックで、利便性があります',
        img: 'pack03.jpg',
        class: 'form_click_w100',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'box_sample04',
        value: 'ミニパック(テトラパック糸有)',
        size: ['1辺約60mm'],
        text: '三角錐の形をしており、見た目がよく抽出効率も向上',
        capacity: '・中身によってはメッシュタイプもお選びいただけます。',
        img: 'pack04.jpg',
        class: 'form_click_w100',
        open: false,
        checked: false,
        timer: false
      }],
      foodboxes: [{
        id: 'box_sample05',
        value: '三方包装',
        size: ['75~110mm×50~60mm'],
        img: 'pack03.jpg',
        class: '',
        open: false,
        checked: false,
        timer: false
      }, {
        id: 'box_sample06',
        value: 'スティック包装',
        size: ['130mm×20mm'],
        img: 'pack04.jpg',
        class: '',
        open: false,
        checked: false,
        timer: false
      }]
    };
  },
  computed: _objectSpread({}, mapState(['contact'])),
  watch: {
    // 味サンプルの選択を監視 2つより多ければエラー文言表示
    'contact.input.taste_sample_select': function contactInputTaste_sample_select(el) {
      if (el.length > 2) {
        this.contact.err.taste_sample = '2種類まで選択可能です。';
      } else {
        this.contact.err.taste_sample = '';
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    var hash = location.hash.replace('#', '') || false;
    var tgt = hash === 'mail' ? document.querySelector('#' + hash) : false;
    var style = document.querySelector('.main').style;

    if (tgt) {
      var top = tgt.getBoundingClientRect().top;
      window.scrollTo(0, 0);
      style.opacity = 0;
      setTimeout(function () {
        window.scrollTo(0, top);
        style.opacity = 1;
      }, 1000);
    } else {
      window.scrollTo(0, 0);
    }

    var unwatch = this.$store.watch(function (state, getters) {
      return getters['contact/check'];
    }, function (status) {
      _this.success(status);
    }); // 初期状態 チェックボックス

    if (this.contact.input.taste_sample === '要') {
      this.taste_sample = 'show';
    }

    if (this.contact.input.box_sample === '要') {
      this.box_sample = 'show';
    } // デバイス判定


    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  methods: {
    checkDevice: function checkDevice() {
      if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0) {
        this.isMobile = true;
      } else if (navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    // スマホのタッチイベント 5秒で解除
    openBalloon: function openBalloon(ev, el, target) {
      if (this.isMobile) {
        var check = target.find(function (elem) {
          return elem === el.value;
        });
        var arr = [this.teas, this.foods, this.teaboxes, this.foodboxes];
        arr.forEach(function (element) {
          element.forEach(function (elem) {
            elem.open = false;
          });
        });

        if (check) {
          el.open = false;
          el.checked = false;

          if (el.timer) {
            window.clearTimeout(el.timer);
            el.timer = false;
          }
        } else {
          el.open = true;
          el.checked = true;
          el.timer = window.setTimeout(function () {
            el.open = false;
            el.timer = false;
          }, 5000);
        }
      }
    },
    closeBalloon: function closeBalloon(ev, el) {
      el.open = false;
    },
    updateInput: function updateInput(e) {
      this.$store.commit('contact/updateText', {
        value: e.target.value,
        id: e.target.id
      });
    },
    pushData: function pushData(e) {
      // エラー文言 リセット
      this.contact.err.taste_sample = '';
      this.$store.commit('contact/checkData');
    },
    success: function success(status) {
      if (this.contact.errFlg && status) {
        console.log('err');
        return false;
      } else {
        this.$router.push({
          name: 'contactcheck'
        }).catch(function (err) {});
      }
    },
    taste_sample_clickShow: function taste_sample_clickShow(e) {
      this.taste_sample = 'show';
    },
    taste_sample_clickHide: function taste_sample_clickHide(e) {
      this.taste_sample = 'hide';
    },
    box_sample_clickShow: function box_sample_clickShow(e) {
      this.box_sample = 'show';
    },
    box_sample_clickHide: function box_sample_clickHide(e) {
      this.box_sample = 'hide';
    },
    // フリガナ フォーカスアウトでチェック
    input_check_kana: function input_check_kana() {
      if (this.contact.input.kana !== '') {
        if (/[^ア-ン　]/.test(this.contact.input.kana)) {
          this.contact.err.kana = '全角カタカナで入力してください。';
        } else {
          this.contact.err.kana = '';
        }
      }
    },
    // 郵便番号 フォーカスアウトでチェック
    input_check_zip: function input_check_zip() {
      if (this.contact.input.zip !== '') {
        if (/[^0-9\-]/.test(this.contact.input.zip)) {
          this.contact.err.zip = '半角数字で入力してください。';
        } else if (!/^\d{3}[-]\d{4}$|^\d{7}$/.test(this.contact.input.zip)) {
          this.contact.err.zip = '正しく入力してください';
        } else {
          this.contact.err.zip = '';
        }
      }
    },
    // 送付先 郵便番号 フォーカスアウトでチェック
    input_check_zip_2: function input_check_zip_2() {
      if (this.contact.input.zip_2 !== '') {
        if (/[^0-9\-]/.test(this.contact.input.zip_2)) {
          this.contact.err.zip_2 = '半角数字で入力してください。';
        } else if (!/^\d{3}[-]\d{4}$|^\d{7}$/.test(this.contact.input.zip_2)) {
          this.contact.err.zip_2 = '正しく入力してください';
        } else {
          this.contact.err.zip_2 = '';
        }
      }
    },
    // 電話番号 フォーカスアウトでチェック
    input_check_tel: function input_check_tel() {
      if (this.contact.input.tel !== '') {
        if (/[^0-9\-]/.test(this.contact.input.tel)) {
          this.contact.err.tel = '半角数字で入力してください。';
        } else {
          this.contact.err.tel = '';
        }
      }
    },
    // メールアドレス フォーカスアウトでチェック
    input_check_email: function input_check_email() {
      if (this.contact.input.email !== '') {
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(this.contact.input.email)) {
          this.contact.err.email = 'メールアドレス形式で入力してください。';
        } else {
          this.contact.err.email = '';
        }
      }
    },
    // メールアドレス フォーカスアウトでチェック
    input_check_remail: function input_check_remail() {
      if (this.contact.input.remail !== '') {
        if (!/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(this.contact.input.remail)) {
          this.contact.err.remail = 'メールアドレス形式で入力してください。';
        } else {
          this.contact.err.remail = '';
        }
      }
    }
  }
};