var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "column_wrapper" },
      [
        _c("article", { staticClass: "column_detail" }, [
          _c("h3", { staticClass: "column_detail_heading" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.column.columnDetail.title) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("time", { staticClass: "column_detail_date" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.column.columnDetail.date) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column_detail_image" }, [
            _c("img", {
              attrs: {
                src: _vm.column.columnDetail.image
                  ? _vm.column.columnDetail.image.url + "?w=1280"
                  : "",
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "column_detail_body",
            attrs: { alt: "" },
            domProps: { innerHTML: _vm._s(_vm.column.columnDetail.body) },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "column_detail_btn _pcHide" },
            [
              _vm.column.columnIdAll.findIndex(function (el) {
                return el === _vm.column.columnDetail.id
              })
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-primary-border btn-long",
                      on: {
                        click: function ($event) {
                          return _vm.generateQuery(-1)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-angle-left" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary-border btn-long",
                  attrs: { to: { name: "clist" } },
                },
                [
                  _c("i", { staticClass: "fa fa-angle-left" }),
                  _vm._v(" 一覧へ戻る\n        "),
                ]
              ),
              _vm._v(" "),
              _vm.column.columnIdAll.findIndex(function (el) {
                return el === _vm.column.columnDetail.id
              }) +
                1 <
              _vm.column.columnIdAll.length
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-primary-border btn-long",
                      on: {
                        click: function ($event) {
                          return _vm.generateQuery(1)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-angle-right" })]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("column-side"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column_detail_btn _spHide" },
      [
        _vm.column.columnIdAll.findIndex(function (el) {
          return el === _vm.column.columnDetail.id
        })
          ? _c(
              "span",
              {
                staticClass: "btn btn-primary-border btn-small",
                on: {
                  click: function ($event) {
                    return _vm.generateQuery(-1)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-angle-left" }),
                _vm._v(" 次の記事\n    "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "btn btn-primary-border btn-long",
            attrs: { to: { name: "clist" } },
          },
          [
            _c("i", { staticClass: "fa fa-angle-up" }),
            _vm._v(" 一覧へ戻る\n    "),
          ]
        ),
        _vm._v(" "),
        _vm.column.columnIdAll.findIndex(function (el) {
          return el === _vm.column.columnDetail.id
        }) +
          1 <
        _vm.column.columnIdAll.length
          ? _c(
              "span",
              {
                staticClass: "btn btn-primary-border btn-small",
                on: {
                  click: function ($event) {
                    return _vm.generateQuery(1)
                  },
                },
              },
              [
                _vm._v("\n      前の記事 "),
                _c("i", { staticClass: "fa fa-angle-right" }),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }