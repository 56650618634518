import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
/*********************************************************
 # Imports
 import Name from '.modules/filename';
 *********************************************************/

import tourMap from './modules/tourMap';
import news from './modules/news';
import column from './modules/column';
import contact from './modules/contact';
export default new Vuex.Store({
  modules: {
    tourMap: tourMap,
    news: news,
    column: column,
    contact: contact
  }
});