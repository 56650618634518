var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "content company_history" }, [
    _c("h3", { staticClass: "content_heading" }, [_vm._v("\n    沿革\n  ")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "company_table" },
      _vm._l(_vm.contents, function (item, index) {
        return _c("tr", { key: index }, [
          _c("th", [_vm._v(_vm._s(item.date))]),
          _vm._v(" "),
          _c("td", [_c("div", { domProps: { innerHTML: _vm._s(item.body) } })]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }