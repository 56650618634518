import "core-js/modules/es6.regexp.replace.js";

/*********************************************************
 # Init
*********************************************************/
require('./bootstrap');

window.Vue = require('vue');
import 'es6-promise/auto';
import axios from 'axios';
import Vuex from 'vuex';
import store from './store/index.js';
import { mapState } from 'vuex';
import VueRouter from 'vue-router';
import routes from './router/routes.js';
import VueScrollTo from 'vue-scrollto';
import VueAnalytics from 'vue-analytics';
/*********************************************************
  # Imports
  import filename from './components/filename';
*********************************************************/

import compSide from './components/compSide';
import compFooter from './components/compFooter';
import homeTour from './components/homeTour';
import homeSlider from './components/homeSlider';
import tourSlider from './components/tourSlider';
import modalWindow from './components/modalWindow';
import qcSlider from './components/qcSlider';
import techAccordion from './components/techAccordion';
import faqAccordion from './components/faqAccordion';
import columnSide from './components/columnSide';
import columnHome from './components/columnHome';
import newsHome from './components/newsHome';
import companyOverview from './components/companyOverview';
import companyHistory from './components/companyHistory';
import contactHome from './components/contactHome';
import contactAccess from './components/contactAccess';
/*********************************************************
 # Code
*********************************************************/

Vue.use(Vuex);
Vue.use(VueRouter);
var wh = window.innerWidth * .2;

if (window.innerWidth >= 768) {
  wh = 0;
}

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -wh,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
var router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});
Vue.use(VueAnalytics, {
  id: 'UA-54996825-1',
  //your
  router: router
});
new Vue({
  el: '#app',
  store: store,
  router: router,
  components: {
    compSide: compSide,
    compFooter: compFooter,
    homeTour: homeTour,
    homeSlider: homeSlider,
    modalWindow: modalWindow,
    tourSlider: tourSlider,
    qcSlider: qcSlider,
    techAccordion: techAccordion,
    faqAccordion: faqAccordion,
    columnSide: columnSide,
    columnHome: columnHome,
    newsHome: newsHome,
    companyOverview: companyOverview,
    companyHistory: companyHistory,
    contactHome: contactHome,
    contactAccess: contactAccess
  },
  data: {},
  mounted: function mounted() {
    // 別ページ内リンク
    var hash = location.hash.replace('#', '') || false;
    if (hash === 'contact' || /ndetail/.test(hash) || /cdetail/.test(hash)) return;
    var tgt = document.querySelector('#' + hash);
    var style = document.querySelector('.main').style;

    if (tgt) {
      var top = tgt.getBoundingClientRect().top;
      window.scrollTo(0, 0);
      style.opacity = 0;
      setTimeout(function () {
        window.scrollTo(0, top);
        style.opacity = 1;
      }, 1000);
    } // let columnUrl = location.href
    // if((columnUrl.includes('column')) && !(columnUrl.includes('cList'))) {
    //   this.$router.push('cList')
    // }

  },
  methods: {}
});