export default {
  namespaced: true,
  state: {
    isActive: false,
    content: {},
    place: [{
      id: 1,
      icon: './images/tour/01.svg',
      thumb: './images/tour/thumb/01.jpg',
      images: ['./images/tour/images/01.jpg', './images/tour/images/01-2.jpg'],
      video: '',
      name: '清潔室'
    }, {
      id: 2,
      icon: './images/tour/02.svg',
      thumb: './images/tour/thumb/02.jpg',
      images: ['./images/tour/images/02.jpg'],
      video: '',
      name: 'エアシャワー'
    }, {
      id: 3,
      icon: './images/tour/03.svg',
      thumb: './images/tour/thumb/03.jpg',
      images: [],
      video: './videos/01.mp4',
      name: 'W混合機'
    }, {
      id: 4,
      icon: './images/tour/04.svg',
      thumb: './images/tour/thumb/04.jpg',
      images: [],
      video: './videos/03.mp4',
      name: '45本立て打錠機'
    }, {
      id: 5,
      icon: './images/tour/05.svg',
      thumb: './images/tour/thumb/05.jpg',
      images: [],
      video: './videos/04.mp4',
      name: '目視検査機'
    }, {
      id: 6,
      icon: './images/tour/06.svg',
      thumb: './images/tour/thumb/06.jpg',
      images: [],
      video: './videos/02.mp4',
      name: '自動錠剤充填機'
    }, {
      id: 7,
      icon: './images/tour/07.svg',
      thumb: './images/tour/thumb/07.jpg',
      images: [],
      video: './videos/07.mp4',
      name: 'スティック包装機'
    }, {
      id: 8,
      icon: './images/tour/08.svg',
      thumb: './images/tour/thumb/08.jpg',
      images: ['./images/tour/images/08.jpg'],
      video: '',
      name: '原料倉庫'
    }, {
      id: 9,
      icon: './images/tour/09.svg',
      thumb: './images/tour/thumb/09.jpg',
      images: ['./images/tour/images/09.jpg'],
      video: '',
      name: '選別機'
    }, {
      id: 10,
      icon: './images/tour/10.svg',
      thumb: './images/tour/thumb/10.jpg',
      images: ['./images/tour/images/10.jpg'],
      video: '',
      name: 'マイクロウェーブ殺菌機'
    }, {
      id: 11,
      icon: './images/tour/11.svg',
      thumb: './images/tour/thumb/11.jpg',
      images: ['./images/tour/images/11.jpg'],
      video: '',
      name: '発芽用水洗い機'
    }, {
      id: 12,
      icon: './images/tour/12.svg',
      thumb: './images/tour/thumb/12.jpg',
      images: ['./images/tour/images/12.jpg'],
      video: '',
      name: '遠赤外線(セラミック)焙煎機'
    }, {
      id: 13,
      icon: './images/tour/13.svg',
      thumb: './images/tour/thumb/13.jpg',
      images: [],
      video: './videos/05.mp4',
      name: '多目的焙煎機'
    }, {
      id: 14,
      icon: './images/tour/14.svg',
      thumb: './images/tour/thumb/14.jpg',
      images: ['./images/tour/images/14.jpg', './images/tour/images/14-2.jpg'],
      video: '',
      name: 'ブレンド秤量機(マゼラー)'
    }, {
      id: 15,
      icon: './images/tour/15.svg',
      thumb: './images/tour/thumb/15.jpg',
      images: [],
      video: './videos/06.mp4',
      name: '高速パック充填機'
    }, {
      id: 16,
      icon: './images/tour/16.svg',
      thumb: './images/tour/thumb/16.jpg',
      images: ['./images/tour/images/16.jpg', './images/tour/images/16-2.jpg'],
      video: '',
      name: 'エアーソックダクト'
    }, {
      id: 17,
      icon: './images/tour/17.svg',
      thumb: './images/tour/thumb/17.jpg',
      images: ['./images/tour/images/17.jpg'],
      video: '',
      name: '中間倉庫'
    }, {
      id: 18,
      icon: './images/tour/18.svg',
      thumb: './images/tour/thumb/18.jpg',
      images: ['./images/tour/images/18.jpg', './images/tour/images/18-2.jpg'],
      video: '',
      name: '出荷倉庫'
    }, {
      id: 19,
      icon: './images/tour/360view.svg',
      thumb: '',
      images: [],
      video: '',
      name: '360° View'
    }, {
      id: 20,
      icon: './images/tour/360view.svg',
      thumb: '',
      images: [],
      video: '',
      name: '360° View'
    }]
  },
  actions: {},
  mutations: {
    toggleModal: function toggleModal(state, payload) {
      console.log(payload);

      if (payload >= 19) {
        window.open('https://finarl.co.jp/finarl_360/360/');
        return;
      }

      if (payload) {
        state.content = state.place[payload - 1];
      } else {
        state.content = {};
      }

      state.isActive = !state.isActive;
    }
  }
};