var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "content" }, [
    _c(
      "section",
      { staticClass: "content contact_mail-check _mb0", attrs: { id: "mail" } },
      [
        _c("h3", { staticClass: "content_heading" }, [_vm._v("送信完了")]),
        _vm._v(" "),
        _c("p", { staticClass: "content_text" }, [
          _vm._v(
            "\n      お問い合わせいただき、誠にありがとうございます。\n      3営業日以内に担当者からご連絡いたします。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content_text contact_box" }, [
          _vm._v(
            "\n      お問い合わせフォームへの登録完了後、１時間以内に自動返信メールが届かない場合、誤ったメールアドレスが登録されているか、もしくは迷惑メールフォルダに振り分けられている可能性があります。迷惑メールフォルダをご確認いただき自動返信メールが届いていない場合は、再度お問い合わせをお願いいたします。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "contact_success" }, [
          _c("img", { attrs: { src: _vm.images[0], alt: "" } }),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact_success_btn" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-primary-border btn-medium",
          attrs: { href: "/" },
        },
        [
          _c("i", { staticClass: "fa fa-angle-left" }),
          _vm._v(" トップページへ戻る"),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }