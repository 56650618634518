import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
import "core-js/modules/es6.function.name.js";
import axios from 'axios';
export default {
  namespaced: true,
  state: {
    input: {
      company: '',
      division: '',
      name: '',
      kana: '',
      address: '',
      zip: '',
      tel: '',
      email: '',
      remail: '',
      contact: '0',
      body: '',
      privacy: false,
      ip: '',
      host: '',
      referrer: '',
      useragent: '',
      inputtime: '',
      // 味サンプル
      taste_sample: '不要',
      taste_sample_select: [],
      // 梱包サンプル
      box_sample: '不要',
      box_sample_select: [],
      // 送付先
      // address_2: '',
      zip_2: ''
    },
    err: {
      company: '',
      name: '',
      kana: '',
      address: '',
      zip: '',
      tel: '',
      email: '',
      remail: '',
      contact: '',
      body: '',
      privacy: '',
      taste_sample: '',
      // 送付先
      // address_2: '',
      zip_2: ''
    },
    errFlg: false,
    checkStatus: false,
    sendStatus: false,
    scroll: {
      flg: false,
      company: '',
      name: '',
      kana: '',
      address: '',
      // zip: '',
      tel: '',
      email: '',
      remail: '',
      contact: '',
      body: '',
      privacy: '',
      taste_sample: '',
      box_sample: ''
    }
  },
  getters: {
    status: function status(state) {
      return state.sendStatus;
    },
    check: function check(state) {
      return state.checkStatus;
    }
  },
  actions: {
    /**
     * メール送信
     */
    sendMail: function sendMail(_ref, id) {
      var state = _ref.state;
      state.sendStatus = false;
      var params = new URLSearchParams();
      Object.keys(state.input).map(function (e) {
        return params.append(e, state.input[e]);
      });
      return new Promise(function (resolve, reject) {
        axios.post('./mail_send.php', params).then(function (response) {
          state.sendStatus = true;
        }).catch(function (err) {
          state.sendStatus = false;
          console.log(err);
        });
      });
    }
  },
  mutations: {
    resetStatus: function resetStatus(state) {
      state.checkStatus = false;
      state.errFlg = false;
      state.sendStatus = false;
    },
    updateText: function updateText(state, payload) {
      state.input[payload.id] = payload.value;

      if (state.input.inputtime === '') {
        state.input.inputtime = new Date();
      }
    },
    updateUserData: function updateUserData(state, payload) {
      state.input.ip = payload.userAddress;
      state.input.host = payload.userHost;
      state.input.referrer = payload.userReferrer;
      state.input.useragent = payload.userAgent;
    },
    checkData: function checkData(state) {
      state.checkStatus = false;
      state.errFlg = false; // 必須チェック

      Object.keys(state.input).forEach(function (e) {
        if ((state.input[e] === '' || !state.input[e]) && e !== 'division' && e !== 'zip_2') {
          if (state.input.referrer !== '') {
            state.err[e] = '必須項目です';
            state.errFlg = true;
          } // state.err[e] = '必須項目です'
          // state.errFlg = true

        } else {
          state.err[e] = '';
        }
      });

      if (!/^\d{3}[-]\d{4}$|^\d{7}$/.test(state.input.zip)) {
        state.err.zip = '正しく入力してください';
        state.errFlg = true;
      }

      if (/[^0-9\-]/.test(state.input.zip)) {
        state.err.zip = '半角数字で入力してください。';
        state.errFlg = true;
      }

      if (/[^ア-ン　]/.test(state.input.kana)) {
        state.err.kana = '全角カタカナで入力してください。';
        state.errFlg = true;
      }

      if (state.input.email !== state.input.remail) {
        state.err.remail = 'メールアドレスが一致しません。';
        state.errFlg = true;
      }

      if (state.input.contact === '0') {
        state.err.contact = '選択してください。';
        state.errFlg = true;
      } // 味サンプル


      if (state.input.taste_sample === '要') {
        // 選択がない場合はエラー
        if (state.input.taste_sample_select.length === 0) {
          state.err.taste_sample = '味サンプルを選択してください。';
          state.errFlg = true;
        } else if (state.input.taste_sample_select.length > 2) {
          // 選択の個数が2個より多い場合はエラー
          state.err.taste_sample = '2種類まで選択可能です。';
          state.errFlg = true;
        }
      } // 梱包サンプル


      if (state.input.box_sample === '要') {
        // 選択がない場合はエラー
        if (state.input.box_sample_select.length === 0) {
          state.err.box_sample = '梱包サンプルを選択してください。';
          state.errFlg = true;
        }
      } // 送付先


      if (state.input.zip_2) {
        if (!/^\d{3}[-]\d{4}$|^\d{7}$/.test(state.input.zip_2)) {
          state.err.zip_2 = '正しく入力してください';
          state.errFlg = true;
        }
      }

      if (/[^0-9\-]/.test(state.input.zip_2)) {
        state.err.zip_2 = '半角数字で入力してください。';
        state.errFlg = true;
      } // 各項目 エラー時 スクロール flg


      if (state.err.company === '必須項目です') {
        state.scroll.company = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.company = '';
      }

      if (state.err.name === '必須項目です') {
        state.scroll.name = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.name = '';
      }

      if (state.err.kana === '必須項目です') {
        state.scroll.kana = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.kana = '';
      }

      if (state.err.zip === '必須項目です') {
        state.scroll.address = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.address = '';
      }

      if (state.err.address === '必須項目です') {
        state.scroll.address = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.address = '';
      }

      if (state.err.tel === '必須項目です') {
        state.scroll.tel = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.tel = '';
      }

      if (state.err.email === '必須項目です') {
        state.scroll.email = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.email = '';
      }

      if (state.err.remail === 'メールアドレスが一致しません。') {
        state.scroll.remail = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.remail = '';
      }

      if (state.err.taste_sample === '味サンプルを選択してください。' || state.err.taste_sample === '2種類まで選択可能です。') {
        state.scroll.taste_sample = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.taste_sample = '';
      }

      if (state.err.box_sample === '梱包サンプルを選択してください。') {
        state.scroll.box_sample = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.box_sample = '';
      }

      if (state.err.contact === '選択してください。') {
        state.scroll.contact = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.contact = '';
      }

      if (state.err.body === '必須項目です') {
        state.scroll.body = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.body = '';
      }

      if (state.err.privacy === '必須項目です') {
        state.scroll.privacy = 'errScroll';
        state.scroll.flg = true;
      } else {
        state.scroll.privacy = '';
      } // 各項目 エラー時 スムーススクロール


      if (state.scroll.flg) {
        setTimeout(function () {
          var target = document.querySelector('.errScroll');
          var target_top = target.getBoundingClientRect().top;
          window.scrollTo({
            top: target_top + window.pageYOffset,
            behavior: "smooth"
          });
        }, 200);
      }

      if (!state.errFlg) {
        state.checkStatus = true;
      } else {
        state.checkStatus = false;
      }

      if (!state.errFlg) {
        // 入力秒数
        state.input.inputtime = (new Date() - state.input.inputtime) / 1000;
      }
    },
    postProcess: function postProcess(state) {
      Object.keys(state.input).forEach(function (e) {
        state.input[e] = e === 'contact' ? 0 : '';
      });
      state.sendStatus = false;
    }
  }
};